<template>
    <div>
        <v-card class="primary darken-2" style="" >
            <v-card-text style="font-size: 12px; ">
                <p class="white--text subheading pa-0 ma-0 text-xs-center"><b> Congrats!!!</b> you just completed step one, complete the next steps </p>
            </v-card-text>
        </v-card>

        <v-card color="white pa-3" >
            <b>Step 2/3: Your Njangi Contribution Info </b>
            <v-layout wrap row>
                <v-flex xs12 sm6>
                    <v-layout row>
                        <v-flex xs2>
                            <i class="teal--text fas fa-money-bill fa-2x mt-3 pr-1"> </i>                            
                        </v-flex>
                        <v-flex xs9>
                            <v-select
                                class="pt-0 mt-3" color="teal"
                                v-model="njangi_contrib_amount" ref="njangi_contrib_amount"
                                :items="njangi_contrib_amounts"
                                :rules="[v => !!v || 'Contribution Amount is required']"
                                label="Contribution Amount"
                                required
                            ></v-select>
                        </v-flex>
                        <v-flex xs2 >
                            <v-btn @click.stop="$store.commit('activateDialog', true); njangi_contrib_amount_help = true; getHelpMessage()" flat fab small outline color="black" class="my-4" style="height:20px; width: 20px" >
                                <i class="fas fa-question"> </i>                            
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <v-flex xs12 sm6>
                    <v-layout row>
                        <v-flex xs2 sm1>
                            <i class="teal--text fas fa-calendar fa-2x mt-3 pr-1"> </i>                            
                        </v-flex>
                        <v-flex xs9 sm10>
                            <v-select
                                class="pt-0 mt-3" color="teal"
                                v-model="njangi_contrib_interval" ref="njangi_contrib_interval"
                                :items="njangi_contrib_intervals"
                                :rules="[v => !!v || 'Njangi Interval Type is required']"
                                label="Njangi Interval Type"
                                required
                            ></v-select>
                        </v-flex>
                        <v-flex xs2 >
                            <v-btn @click.stop="$store.commit('activateDialog', true); njangi_contrib_amount_help = true; getHelpMessage()" flat fab small outline color="black" class="my-4" style="height:20px; width: 20px" >
                                <i class="fas fa-question"> </i>                            
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <v-flex xs12>            
                    <v-layout row>
                        <v-flex xs2 sm1>
                            <i class="teal--text fas fa-pen-to-square fa-2x mt-3"> </i>                            
                        </v-flex>
                        <v-flex xs9 sm10>
                            <v-textarea class="pa-0 mt-3"
                                name="input-7-1"
                                auto-grow v-model="assetDescription"
                                ref="assets_description"
                                label="Describe your available assets"
                                :rules="[v => !!v || 'Your asset description is required']"
                                background-color="grey lighten-4" color="teal"
                                rows="2" box
                                row-height="20" 
                            ></v-textarea>
                        </v-flex>
                        <v-flex xs2 >
                            <v-btn @click.stop="$store.commit('activateDialog', true); asset_description_help = true; getHelpMessage()" flat fab small outline color="black" class="my-4" style="height:20px; width: 20px" >
                                <i class="fas fa-question"> </i>                            
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <v-flex xs12>            
                    <v-layout row>
                        <v-flex xs2 sm1>
                            <i class="teal--text fas fa-bank fa-2x mt-3"> </i>                            
                        </v-flex>
                        <v-flex xs9 sm10>
                            <v-textarea class="pa-0 mt-3"
                                name="input-7-1"
                                auto-grow v-model="bankAccountDescription"
                                ref="bank_account_description"
                                label="Describe your bank statement"
                                :rules="[v => !!v || 'Your bank statement description is required']"
                                background-color="grey lighten-4" color="teal"
                                rows="2" box
                                row-height="20" 
                            ></v-textarea>
                        </v-flex>
                        <v-flex xs2 >
                            <v-btn @click.stop="$store.commit('activateDialog', true); bank_statement_description_help = true; getHelpMessage()" flat fab small outline color="black" class="my-4" style="height:20px; width: 20px" >
                                <i class="fas fa-question"> </i>                            
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
            <div class="text-xs-center">
                <v-btn @click="configureNewGroup()" class="white--text elevation-6" type="submit" small round  color="teal" >
                    <span> Next</span>
                </v-btn>
            </div>
        </v-card>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                njangi_contrib_amount_help: false,
                asset_description_help: false,
                application: [],

                njangi_contrib_amount: "",
                assetDescription: "",
                njangi_contrib_interval: '',
                bankAccountDescription: "",
                njangi_contrib_amounts: [
                    "2,500 F CFA",
                    "5,000 F CFA",
                    "10,000 F CFA",
                    "25,000 F CFA",
                    "50,000 F CFA",
                    "100,000 F CFA",
                    "250,000 F CFA",
                    "500,000 F CFA",
                    "1,000,000 F CFA",
                ],
                njangi_contrib_intervals: [
                    "Daily",
                    "Weekly",
                    "Monthly",
                ],
            }
        },
        mounted(){
            // executes these after the page has been mounted
            document.title = "PettyCash | Configure Njangi Application"
            this.getNjangiApplication()
        },

        methods:{
            async getNjangiApplication(){
                    const application_id = this.$route.params.application_id  // get group id from url and send request to server
                
                    await axios
                        .get('/api/v1/njangi/get/user/application/detail/'+application_id+'/')
                        .then(response => {
                            console.log(response.data)
                            this.application = response.data
                        })
                        .catch(error => {
                            if (error.response){
                                this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }else{
                                this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }
                        })
                    this.$store.commit('setIsLoading', false)
            },

            async configureNewGroup(){
                if(this.njangi_contrib_amount === ""){
                    this.$store.commit('setSnackBarMessage', "Please select the <b>amount</b> you will be comfortable contributing for each session")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.njangi_contrib_amount.focus()  // this causes a an auto fucos to the element
                }
                else if(this.njangi_contrib_interval === ""){
                    this.$store.commit('setSnackBarMessage', "Please select the contribution intervals you will be at ease with daily/weekly/monthly every njangi session")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.njangi_contrib_interval.focus()  // this causes a an auto fucos to the element
                }
                else if(this.assetDescription === ""){
                    this.$store.commit('setSnackBarMessage', "Please describe your assets so we know how best to consider your application.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.assets_description.focus()  // this causes a an auto fucos to the element
                }
                else if(this.bankAccountDescription === ""){
                    this.$store.commit('setSnackBarMessage', "Please describe your bank details so we know how best to consider your application.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.bank_account_description.focus()  // this causes a an auto fucos to the element
                }
                else {
                    this.$store.commit('setIsLoading', true)
                    const application_id = this.$route.params.application_id  // get group id from url and send request to server
                
                    await axios
                        .post('/api/v1/njangi/configure/new_group/', {'application_id': application_id, 'njangi_contrib_amount': this.njangi_contrib_amount, 'njangi_contrib_interval': this.njangi_contrib_interval, 'assetDescription': this.assetDescription, 'bankAccountDescription': this.bankAccountDescription})
                        .then(response => {
                            this.$store.commit('setSnackBarMessage', response.data[0].text)
                            this.$store.commit('setSnackBarColor', response.data[0].color)
                            this.$store.commit('activateSnackBar', true)
                            let router = this.$router
                            setTimeout(function(){
                                router.push('/njangi/groups/new_group/three/')
                            }, 4000)
                        })
                        .catch(error => {
                            if (error.response){
                                this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }else{
                                this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }
                        })
                    this.$store.commit('setIsLoading', false)
                }
            },

            getHelpMessage(){
                if (this.njangi_contrib_amount_help){
                    this.njangi_contrib_amount_help = false
                    this.$refs.njangi_contrib_amount.focus()
                    this.$store.commit('setDialogTitle', "Contibution Cycle Type")
                    this.$store.commit('setDialogMessage', "Your njangi meetings, will they hold on daily, weekly or monthly bases, You decide by choosing the options available. <b>After</b> choosing this, the next field you will have to choose the number of days/weeks/months")
                    this.$store.commit('activateDialog', true)
                }else if (this.collecting_interval_help){
                    this.collecting_interval_help = false
                    this.$refs.collecting_interval.focus()
                    this.$store.commit('setDialogTitle', "Meeting Interval")
                    this.$store.commit('setDialogMessage', "This will be the duration between two njangi meetings held. It will take into consideration the njangi cycle type you inputed above.")
                    this.$store.commit('activateDialog', true)
                }else if (this.no_people_collect_per_cycle_help){
                    this.no_people_collect_per_cycle_help = false
                    this.$refs.no_people_collect_per_cycle.focus()
                    this.$store.commit('setDialogTitle', "Money Attribution")
                    this.$store.commit('setDialogMessage', "To how many people will the contributed sum of money be attributed to after every njangi contibution session. You can have the standard one person or two any choice? Just input the number you wish")
                    this.$store.commit('activateDialog', true)
                }else if (this.contribution_amount_help){
                    this.contribution_amount_help = false
                    this.$refs.contribution_amount.focus()
                    this.$store.commit('setDialogTitle', "Membership Contributions")
                    this.$store.commit('setDialogMessage', "Each member of the njangi will have to contibute a certain amount before every meeting. How much will it be, please input this amount into the coffers.")
                    this.$store.commit('activateDialog', true)
                }else if (this.contribution_account_amount_help){
                    this.contribution_account_amount_help = false
                    this.$refs.contribution_account_amount.focus()
                    this.$store.commit('setDialogTitle', "Account Contributions")
                    this.$store.commit('setDialogMessage', "After every njangi contribution session, some money from the total contibution will be deducted and kept for emergencies and upcoming programs in the njangi's account. You will have to input the amount")
                    this.$store.commit('activateDialog', true)
                }
            },
        },
    }
</script>
